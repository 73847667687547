<template>
  <div class="console">
    <div class="left-nav">
      <Menu
        :width="'auto'"
        @on-select="handleNavChange"
        :open-names="openName"
        :active-name="activeName"
      >
      <template v-if="userInfo.maxRoleType=='ADMINISTRATOR'">
        <MenuItem name="userlist">
          <Icon type="md-chatbubbles" />用户管理
        </MenuItem>
        <MenuItem name="recordlist">
          <Icon type="md-list-box" />操作日志
        </MenuItem>
      </template>
      <template v-else>
      <MenuItem name="auth"> <Icon type="md-document" />企业授权 </MenuItem>
        <MenuItem name="type"> <Icon type="md-pricetag" />工种管理 </MenuItem>
        <MenuItem name="blacklist">
          <Icon type="md-chatbubbles" />失信人员
        </MenuItem>
        <MenuItem name="safelist">
          <Icon type="md-list-box" />企业安全交底表
        </MenuItem>
        <MenuItem name="videolist">
          <Icon type="md-appstore" />企业视频库
        </MenuItem>
        <MenuItem name="areaauth" v-if="areaAuthFlag">
          <Icon type="md-document" />片区管理授权
        </MenuItem>
        <MenuItem name="overView" v-if="currentUserInfo.login_id == 19090901111">
          <Icon type="md-appstore" />数据大屏
        </MenuItem>
      </template>
           
      </Menu>
    </div>
    <div class="right-main">
      <div class="content-box" v-if="currentBox == 'auth'">
        <Card
          style="
            width: 100%;
            margin-bottom: 20px;
            max-width: 1300px;
            position: relative;
            overflow: hidden;
          "
        >
          <Button
            type="primary"
            style="margin-bottom: 8px"
            @click="toAddAuth"
            >新增授权</Button
          >
          <Table
            :columns="authColumnsList"
            :data="authDataList"
            :loading="authListLoading"
          >
            <template slot-scope="{ row, index }" slot="action">
              <Button
                type="warning"
                @click="delAuth(row)"
                style="z-index: 10"
                >移除</Button
              >
              <span v-if="row.name||row.phone" style="margin-top:10px;margin-left:10px">
                  <Button
                  v-if="row.showOrHide=='hide'
                  "
                  type="primary"
                  @click.stop="showAuthConceal(row)"
                  >显示</Button
                  >
                  <Button
                    v-else
                    type="primary"
                    @click.stop="hideAuthConceal(row)"
                    >隐藏</Button
                  >

                </span>
            </template>
          </Table>
          <!-- <Page class="page-box" :total="100" /> -->
        </Card>
      </div>
      <div class="content-box" v-if="currentBox == 'type'">
        <Card
          style="
            width: 100%;
            margin-bottom: 20px;
            max-width: 1300px;
            position: relative;
            overflow: hidden;
          "
        >
          <div class="second-title">
            <div class="shu" />
            企业工种
          </div>
          <div class="c-btnbox">
            <div class="c-btnbox-item" style="margin-bottom: 14px">
              <div class="mb-item">
                <div class="label">工种：</div>
                <Select
                  v-model="workwork.corpWorkType"
                  multiple
                  :max-tag-count="3"
                  transfer
                  style="width: 250px; margin-right: 14px"
                >
                  <Option
                    v-for="item in workTypeList"
                    :value="item.value"
                    :key="item.id"
                    >{{ item.name }}</Option
                  >
                </Select>
              </div>
            </div>
            <div class="c-btnbox-item" style="margin-bottom:20px">
              <Button type="primary" @click="toAddCorpWorkType"
                >添加企业工种</Button
              >
            </div>
            <div>{{subWorkType}}</div>
          </div>
        </Card>
      </div>
      <div class="content-box" v-if="currentBox == 'blacklist'">
        <Card
          style="
            width: 100%;
            margin-bottom: 20px;
            max-width: 1300px;
            position: relative;
            overflow: hidden;
          "
        >
          <!-- <div class="bgimg">
            <img src="../../assets/images/dgtech_logo.png" alt />
          </div>-->
          <div class="second-title">
            <div class="shu" />
            失信人员条件筛选
          </div>
          <div class="c-btnbox">
            <div class="c-btnbox-item" style="margin-bottom: 14px">
              <div class="mb-item">
                <div class="label">姓名：</div>
                <Input
                  v-model="searchBlackListData.name"
                  placeholder="请输入人员姓名"
                  style="width: 200px; margin-right: 14px"
                />
              </div>
              <div class="mb-item">
                <div class="label">手机号码：</div>
                <Input
                  v-model="searchBlackListData.phone"
                  placeholder="请输入人员手机号码"
                  style="width: 200px; margin-right: 14px"
                />
              </div>
              <div class="mb-item">
                <div class="label">身份证号码：</div>
                <Input
                  v-model="searchBlackListData.idcard"
                  placeholder="请输入人员身份证号码"
                  style="width: 200px; margin-right: 14px"
                />
              </div>
            </div>
            <div class="c-btnbox-item">
              <!-- <Button type="primary" shape="circle" style="margin-right:14px;" icon="md-add">批量导入失信人员</Button> -->
              <Button
                type="primary"
                style="margin-right: 14px"
                @click="handleBlacklistSearch('search')"
                >搜索</Button
              >
              <Button
                style="margin-right: 14px"
                @click="handleBlacklistSearch('reset')"
                >重置</Button
              >
            </div>
            <div class="c-btnbox-item"></div>
          </div>
          <div class="second-title">
            <div class="shu" />
            失信人员批量操作
          </div>
          <div class="c-btnbox">
            <div class="c-btnbox-item">
              <Button
                style="margin-right: 14px"
                icon="ios-cloud-download-outline"
                @click="downLoadBalckListModel"
                >下载失信名单模板</Button
              >
              <Upload
                action="/sapi/blackList/upload"
                :name="'file'"
                :multiple="false"
                :before-upload="onBefore"
                :accept="Accept"
                :format="Format"
                :data="uploadData"
                v-if="currentUserInfo.dg_key == 1"
              >
                <Button icon="ios-cloud-upload-outline"
                  >批量导入失信名单</Button
                >
              </Upload>
            </div>
            <div class="c-btnbox-item"></div>
          </div>
          <div class="second-title">
            <div class="shu" />
            失信人员操作
          </div>
          <div class="c-btnbox">
            <div class="c-btnbox-item">
              <Button
                style="margin-right: 14px"
                icon="md-add"
                type="primary"
                @click="addBlackMan"
                >新增失信人员</Button
              >
            </div>
          </div>
          <Table
            :columns="blackColumsList"
            :data="blackDataList"
            :loading="blackListLoading"
          >
            <template slot-scope="{ row, index }" slot="action">
              <div class="btnbox-flex">
                <Button
                  type="primary"
                  style="margin-right: 10px"
                  v-if="currentUserInfo.dg_key == 1"
                  @click="editBlack(row)"
                  >编辑</Button
                >
                <Button
                  type="warning"
                  v-if="currentUserInfo.dg_key == 1"
                  @click="removeBlackList(row)"
                  >移除</Button
                >
                <span v-if="row.name||row.idcard||row.nativePlace" style="margin-top:10px;margin-left:10px">
                  <Button
                  v-if="row.showOrHide=='hide'
                  "
                  type="primary"
                  @click.stop="showBlackConceal(row)"
                  >显示</Button
                  >
                  <Button
                    v-else
                    type="primary"
                    @click.stop="hideBlackConceal(row)"
                    >隐藏</Button
                  >

                </span>
              </div>
            </template>
          </Table>
          <Page
            class="page-box"
            :total="blackListTotal"
            :page-size="blackListSize"
            @on-change="handleBlacklistPageChange"
          />
        </Card>
      </div>
      <div class="content-box" v-if="currentBox == 'safelist'">
        <Card
          style="
            width: 100%;
            margin-bottom: 20px;
            max-width: 1300px;
            position: relative;
            overflow: hidden;
          "
        >
        <Securitycompany :userInfo="userInfo" />
          <!-- <div class="second-title">
            <div class="shu" />
            安全技术交底表
          </div>
          <Cascader
            :data="safeSelectData"
            v-model="safeSelectValue"
            style="width: 500px; margin-bottom: 14px; margin-left: 14px"
            placeholder="请选择安全交底表格"
            @on-change="handleSafeSelectChange"
            filterable
          />
          <Button
            style="margin-left: 14px"
            type="success"
            icon="ios-copy-outline"
            @click="updateSafeDisclose"
            >获取企业模板</Button
          >
          <Button
            style="margin-left: 14px"
            type="primary"
            icon="ios-download-outline"
            @click="downSafeList"
            >下载安全技术交底表</Button
          >
          <div class="second-title" style="margin-top: 14px">
            <div class="shu" />
            安全技术交底表内容
          </div>
          <div class="tab-btnbox">
            <Button
              style="margin-right: 8px"
              @click="toEditSafeContent"
              type="info"
              v-if="!editSafeFlag"
              >编辑安全技术交底表</Button
            >
            <Button
              style="margin-right: 8px"
              type="success"
              v-if="editSafeFlag"
              @click="toChangeSafeContent('save')"
              >保存</Button
            >
            <Button
              style="margin-right: 8px"
              v-if="editSafeFlag"
              @click="toChangeSafeContent('cancel')"
              >取消</Button
            >
          </div>
          <div style="position: relative">
            <Spin fix v-if="getSafeContentLoading">
              <Icon
                type="ios-loading"
                size="18"
                class="demo-spin-icon-load"
              ></Icon>
              <div>Loading</div>
            </Spin>
            <Input
              v-model="currentSafeContent"
              :disabled="!editSafeFlag"
              :autosize="true"
              class="tab-textarea"
              type="textarea"
              placeholder="安全交底表内容"
            />
          </div> -->
        </Card>
      </div>
      <div class="content-box" v-if="currentBox == 'videolist'">
        <Card
          style="
            width: 100%;
            margin-bottom: 20px;
            position: relative;
            overflow: hidden;
          "
        >
          <!-- <div class="bgimg">
            <img src="../../assets/images/dgtech_logo.png" alt />
          </div>-->
          <div class="second-title">
            <div class="shu" />
            视频条件筛选
          </div>
          <div class="c-btnbox">
            <div class="c-btnbox-item" style="margin-bottom: 14px">
              <div class="mb-item">
                <div class="label">视频标题：</div>
                <Input
                  placeholder="请输入视频标题"
                  style="width: 200px; margin-right: 14px"
                  v-model="searchVideoData"
                />
              </div>
            </div>
            <div class="c-btnbox-item">
              <Button
                type="primary"
                style="margin-right: 14px"
                @click="handleVideoListSearch"
                >搜索</Button
              >
              <Button
                style="margin-right: 14px"
                @click="handleVideoListSearch('reset')"
                >重置</Button
              >
            </div>
            <div class="c-btnbox-item"></div>
          </div>
          <div class="second-title">
            <div class="shu" />
            视频操作
          </div>
          <div class="c-btnbox">
            <div class="c-btnbox-item">
              <Button
                style="margin-right: 14px"
                icon="md-add"
                type="primary"
                @click="handleNavChange('videoedit')"
                >新增视频</Button
              >
            </div>
          </div>
          <Table
            :columns="videoColumsList"
            :data="videoDataList"
            :loading="videoListLoading"
          >
            <template slot-scope="{ row, index }" slot="icon">
              <div class="head-img" @click="showQR(row.coverUrl)">
                <img v-if="row.coverUrl" :src="row.coverUrl" alt />
                <img v-else src="../../assets/images/test_head_icon.png" alt />
              </div>
            </template>
            <template slot-scope="{ row, index }" slot="action">
              <div class="btnbox-flex">
                <Button
                  type="info"
                  style="margin-right: 6px"
                  @click="toViewFile(row.fileUrl)"
                  >视频预览</Button
                >
                <Button
                  type="info"
                  style="margin-right: 6px"
                  @click="toViewFile(row.pdfUrl)"
                  >pdf预览</Button
                >
                <Button
                  type="primary"
                  style="margin-right: 6px"
                  @click="editVideo(row)"
                  >编辑</Button
                >
                <Button type="warning" @click="removeVideo(row)">移除</Button>
              </div>
            </template>
          </Table>
          <Page
            class="page-box"
            :total="videoListTotal"
            :page-size="videoListSize"
            @on-change="handleVideoListPageChange"
          />
        </Card>
      </div>
      <div class="content-box" v-if="currentBox == 'videoedit'">
        <Card
          style="
            width: 100%;
            margin-bottom: 20px;
            position: relative;
            overflow: hidden;
          "
        >
          <div class="second-title">
            <div class="shu" />
            添加视频
          </div>
          <div class="new-btn-box">
            <div class="nbb-item">
              <div class="label">视频标题</div>
              <Input
                placeholder="请输入视频标题"
                style="width: 300px; margin-right: 14px"
                v-model="newVideoData.title"
              />
            </div>
            <div class="nbb-item">
              <div class="label">视频分类</div>
              <Select v-model="newVideoData.type" style="width: 300px">
                <Option
                  v-for="item in videoTypeList"
                  :value="item.id"
                  :key="item.id"
                  >{{ item.name }}</Option
                >
              </Select>
            </div>
            <div class="nbb-item">
              <div class="label">视频简介</div>
              <Input
                v-model="newVideoData.desc"
                maxlength="300"
                autosize
                show-word-limit
                type="textarea"
                placeholder="输入视频简介"
                style="width: 300px"
              />
            </div>
            <div class="nbb-item">
              <div class="label">上传视频</div>
              <uploader
                :key="uploader_key"
                :options="options"
                class="uploader-example"
                :autoStart="false"
                @file-success="onFileSuccess"
                @file-added="filesAdded"
              >
                <Spin fix v-if="videoLoading">
                  <Icon
                    type="ios-loading"
                    size="18"
                    class="demo-spin-icon-load"
                  ></Icon>
                  <div>Loading</div>
                </Spin>
                <uploader-unsupport></uploader-unsupport>
                <uploader-drop>
                  <uploader-btn :single="true" :attrs="attrs">{{
                    videoFileId ? "上传更新视频" : "上传新增视频"
                  }}</uploader-btn>
                </uploader-drop>
                <uploader-list></uploader-list>
              </uploader>
            </div>
            <div class="nbb-item">
              <div class="label">上传教材</div>
              <!-- v-if="currentUserInfo.dg_key == 1" -->
              <Upload
                action="/sapi/blackList/upload"
                :name="'file'"
                :multiple="false"
                :before-upload="onBeforePdf"
                :accept="AcceptPdf"
                :format="FormatPdf"
                :data="uploadDataPdf"
              >
                <Button icon="ios-cloud-upload-outline">{{
                  pdfId ? "点击更换教材" : "点击上传教材"
                }}</Button>
              </Upload>
            </div>
            <div class="nbb-item" style="margin-top: 14px">
              <Button
                type="success"
                style="margin-right: 14px"
                @click="toSaveVideo"
                >保存</Button
              >
              <Button @click="toSaveVideo('cancle')">取消</Button>
            </div>
          </div>
        </Card>
      </div>
       <div class="content-box" v-if="currentBox == 'recordlist'">
          <Card
            style="
              width: 100%;
              margin-bottom: 20px;
              max-width: 1300px;
              position: relative;
              overflow: hidden;
            "
          >
          <div class="second-title">
            <div class="shu" />
            模糊条件筛选
          </div>
          <div class="c-btnbox">
            <div class="c-btnbox-item" style="margin-bottom: 14px">
              <div class="mb-item">
                <div class="label">模糊查询：</div>
                <Input
                  v-model="searchRecordListData.keyword"
                  placeholder="请输入关键字"
                  style="width: 200px; margin-right: 14px"
                />
              </div>
            </div>
            <div class="c-btnbox-item">
              <Button
                type="primary"
                style="margin-right: 14px"
                @click="handleRecordlistSearch('search')"
                >搜索</Button
              >
              <Button
                style="margin-right: 14px"
                @click="handleRecordlistSearch('reset')"
                >重置</Button
              >
            </div>
            <div class="c-btnbox-item"></div>
          </div>
          <Table
              :columns="recordColumnsList"
              :data="recordDataList"
              :loading="recordListLoading"
            >
            </Table>
            <Page
              class="page-box"
              :total="recordListTotal"
              :page-size="recordListSize"
              @on-change="handleRecordlistPageChange"
            />
          </Card>
      </div>
      <!-- 用户管理 -->
      <div class="content-box" v-if="currentBox == 'userlist'">
          <Card
            style="
              width: 100%;
              margin-bottom: 20px;
              max-width: 1300px;
              position: relative;
              overflow: hidden;
            "
          >
          <div class="second-title">
            <div class="shu" />
            用户列表条件筛选
          </div>
          <div class="c-btnbox">
            <div class="c-btnbox-item" style="margin-bottom: 14px">
              <div class="mb-item">
                <Input
                  v-model="searchUserListData.query"
                  placeholder="请输入名字或者手机号码搜索"
                  style="width: 230px; margin-right: 14px"
                />
                <el-select style="width: 200px; margin-right: 14px" v-model="searchUserListData.corpId" size="mini" class="sort-style" @change="onOptionCorpChange" filterable placeholder="请选择企业">
                  <el-option
                    v-for="item in corpOptions"
                    :key="item.id"
                    :label="item.vname"
                    :value="item.id">
                  </el-option>
                </el-select>
                <el-select style="width: 200px; margin-right: 14px" v-model="searchUserListData.projectId" size="mini" class="sort-style" @change="onOptionProjectChange" filterable placeholder="请选择项目">
                  <el-option
                    v-for="item in projectOptions"
                    :key="item.id"
                    :label="item.vprojectname"
                    :value="item.id">
                  </el-option>
                </el-select>
                <el-select style="width: 200px; margin-right: 14px" v-model="searchUserListData.teamId" size="mini" class="sort-style" @change="onOptionTeamChange" filterable placeholder="请选择班组">
                  <el-option
                    v-for="item in teamOptions"
                    :key="item.id"
                    :label="item.vteamName"
                    :value="item.id">
                  </el-option>
                </el-select>
      
              </div>
            </div>
            <div class="c-btnbox-item">
              <Button
                type="primary"
                style="margin-right: 14px"
                @click="handleUserlistSearch('search')"
                >搜索</Button
              >
              <Button
                style="margin-right: 14px"
                @click="handleUserlistSearch('reset')"
                >重置</Button
              >
            </div>
            <div class="c-btnbox-item"></div>
          </div>
          <Table
              :columns="userColumnsList"
              :data="userDataList"
              :loading="userListLoading"
            >

            <template slot-scope="{ row }" slot="sex">
               <span>{{ row.sex == 1? '男': row.sex == 2?"女": "未知" }}</span>
            </template>
             <template slot-scope="{ row }" slot="isRealname">
               <span>{{ row.isRealname == 1? '已实名':"未实名" }}</span>
            </template>
             <template slot-scope="{ row }" slot="publishTaskStatus">
                <span>{{ row.publishTaskStatus == 1? '任务正常':"缺任务" }}</span>
            </template>


            </Table>
            <Page
              class="page-box"
              :total="userListTotal"
              :page-size="userListSize"
              @on-change="handleUserlistPageChange"
            />
          </Card>
      </div>
     
      <div class="content-box" v-if="currentBox == 'areaauth'">
        <Card
          style="
            width: 100%;
            margin-bottom: 20px;
            max-width: 1300px;
            position: relative;
            overflow: hidden;
          "
        >
          <Button
            type="primary"
            style="margin-bottom: 8px"
            @click="toAddAuth('area')"
            v-if="currentUserInfo.dg_key == 1"
            >新增授权</Button
          >
          <Table
            :columns="areaAuthColumnsList"
            :data="areaAuthDataList"
            :loading="areaAuthListLoading"
          >
            <template slot-scope="{ row, index }" slot="action">
              <Button
                type="primary"
                @click="editZoneAuth(row)"
                style="z-index: 10; margin-right: 8px"
                v-if="currentUserInfo.dg_key == 1"
                >编辑</Button
              >
              <Button
                type="warning"
                @click="delZoneAuth(row)"
                style="z-index: 10"
                v-if="currentUserInfo.dg_key == 1"
                >移除</Button
              >
            </template>
          </Table>
          <!-- <Page class="page-box" :total="100" /> -->
        </Card>
      </div>
    </div>
    <Modal
      v-model="addNewAuthFlag"
      title="新增授权"
      @on-ok="addAuth"
      @on-cancel="addAuth('close')"
      :loading="addAuthNext"
      :mask-closable="false"
    >
      <div class="aa-item">
        <div class="label">姓名：</div>
        <Input
          v-model="newAuthData.name"
          placeholder="输入授权管理员姓名"
          clearable
          style="width: 200px"
        />
      </div>
      <div class="aa-item">
        <div class="label">手机号：</div>
        <Input
          v-model="newAuthData.phone"
          placeholder="输入授权管理员手机号"
          clearable
          style="width: 200px"
        />
      </div>
      <div class="aa-item" v-if="areaAuthFlag">
        <div class="label">管理片区：</div>
        <Select v-model="newAuthData.authArea" multiple style="width: 200px">
          <Option
            v-for="item in zoneList"
            :value="item.value"
            :key="item.value"
            >{{ item.label }}</Option
          >
        </Select>
      </div>
    </Modal>
    <!-- 复制交底表 -->
    <Modal
      v-model="copySafeDiscloseFlag"
      title="获取企业安全技术交底表模板"
      @on-ok="toPickVersion"
      :mask-closable="false"
    >
      <div class="aa-item">
        <div class="label">模板版本：</div>
        <Select v-model="currentVersion" style="width: 280px">
          <Option
            v-for="item in versionList"
            :value="item.value"
            :key="item.value"
            >{{ item.label }}</Option
          >
        </Select>
      </div>
    </Modal>
    <!-- 新增黑名单 -->
    <Modal
      v-model="addNewBlackFlag"
      title="新增黑名单"
      @on-ok="addBlack"
      @on-cancel="addBlack('close')"
      :loading="addNewBlackNext"
      :mask-closable="false"
    >
      <div class="aa-item">
        <div class="label bitian">姓名：</div>
        <Input
          v-model="newBlackData.name"
          placeholder="输入姓名"
          clearable
          style="width: 200px"
        />
      </div>
      <div class="aa-item">
        <div class="label bitian">身份证：</div>
        <Input
          v-model="newBlackData.idcard"
          placeholder="输入身份证号码"
          clearable
          style="width: 200px"
        />
      </div>
      <div class="aa-item">
        <div class="label">籍贯：</div>
        <Input
          v-model="newBlackData.nativePlace"
          placeholder="输入籍贯"
          clearable
          style="width: 200px"
        />
      </div>
      <div class="aa-item">
        <div class="label">事件班组名称：</div>
        <Input
          v-model="newBlackData.eventTeamName"
          placeholder="输入事件班组名称"
          clearable
          style="width: 200px"
        />
      </div>
      <div class="aa-item">
        <div class="label">事件项目名称：</div>
        <Input
          v-model="newBlackData.eventProjectName"
          placeholder="输入事件项目名称"
          clearable
          style="width: 200px"
        />
      </div>
      <div class="aa-item">
        <div class="label">事件：</div>
        <Input
          v-model="newBlackData.event"
          placeholder="输入事件"
          clearable
          style="width: 200px"
        />
      </div>
      <div class="aa-item">
        <div class="label">事件时间：</div>
        <Input
          v-model="newBlackData.eventTime"
          placeholder="输入事件时间"
          clearable
          style="width: 200px"
        />
      </div>
      <div class="aa-item">
        <div class="label">备注：</div>
        <Input
          v-model="newBlackData.remark"
          placeholder="输入备注"
          clearable
          style="width: 200px"
        />
      </div>
      <div class="aa-item">
        <div class="label">来源：</div>

        <Select v-model="newBlackData.source" style="width: 200px">
          <Option
            v-for="item in sourceList"
            :value="item.value"
            :key="item.value"
            >{{ item.label }}</Option
          >
        </Select>
        <!-- <Input
          v-model="newBlackData.name"
          placeholder="输入授权管理员姓名"
          clearable
          style="width: 200px"
        /> -->
      </div>
    </Modal>
    <!-- 放大二维码 -->
    <div class="show-qrcode" v-show="showQRFlag" @click="hiddenQR">
      <div class="qrcode-img">
        <img :src="showImgSrc" alt />
      </div>
    </div>
  </div>
</template>

<script>
import SparkMD5 from "spark-md5";
import Securitycompany from "./template/Securitycompany.vue"
import Storage from "../../utils/localStorage"
import constants from "../../constants/constants"
import {nameConceal,IdcardConceal,phoneConceal,addressConceal} from "@/utils/desensitization"

export default {
  name: "myConsole",
  components: {
    Securitycompany
  },
  data() {
    return {
      Accept: ".xls,.xlsx", //上传文件格式限制
      Format: [".xls", ".xlsx"], //上传文件格式限制
      uploadData: {
        corpId: "",
      },
      AcceptPdf: ".pdf", //上传文件格式限制
      FormatPdf: [".pdf"], //上传文件格式限制
      uploadDataPdf: {
        corpId: "",
      },
      currentUserInfo: {},
      activeName: "auth",
      openName: ["auth"],
      currentBox: "auth",
      authListLoading: false,
      addNewAuthFlag: false,
      addAuthNext: true,
      blackListLoading: false,
      blackListPage: 1,
      blackListTotal: 0,
      blackListSize: 10,
      searchBlackListData: {
        name: "",
        phone: "",
        idcard: "",
      },
      searchRecordListData: {
        keyword: "",
        startTime:undefined,
        endTime:undefined,
      },
      searchUserListData: {
        pageNo: 1,
        pageSize:10,
        desc: 'id',
        query: null,
        corpId:null,
        projectId:null,
        teamId:null,
        publishTaskStatus: null,
      },
      newAuthData: {
        name: "",
        phone: "",
        authArea: [],
      },

      // blackColumsList: [
      //     {
      //         title: "姓名",
      //         key: "blackName"
      //     },
      //     {
      //         title: "手机号",
      //         key: "blackPhone"
      //     },
      //     {
      //         title: "身份证",
      //         key: "blackIDCard"
      //     },
      //     {
      //         title: "导入时间",
      //         key: "blackInTime"
      //     },
      //     {
      //         title: "籍贯",
      //         key: "nativePlace"
      //     },
      //     {
      //         title: "所属班组",
      //         key: "eventTeamName"
      //     },
      //     {
      //         title: "所属项目",
      //         key: "eventProjectName"
      //     },
      //     {
      //         title: "事件",
      //         key: "private"
      //     },
      //     {
      //         title: "事件时间",
      //         key: "eventTime"
      //     },
      //     {
      //         title: "操作",
      //         slot: "action",
      //         width: 150,
      //         align: "center",
      //     },
      // ],
      blackColumsList: [
        {
          title: "姓名",
          key: "name",
        },
        // {
        //   title: "手机号",
        //   key: "phone",
        // },
        {
          title: "身份证",
          key: "idcard",
        },
        {
          title: "导入时间",
          key: "createTime",
        },
        {
          title: "籍贯",
          key: "nativePlace",
        },
        {
          title: "所属班组",
          key: "eventTeamName",
        },
        {
          title: "所属项目",
          key: "eventProjectName",
        },
        {
          title: "事件",
          key: "event",
        },
        {
          title: "事件时间",
          key: "eventTime",
        },
        // {
        //   title: "备注",
        //   key: "remark",
        // },
        {
          title: "操作",
          slot: "action",
          width: 260,
          align: "center",
        },
      ],
      blackDataList: [],
      blackDataList1: [],

      authColumnsList: [
        // {
        //   title: "Id",
        //   key: "id",
        //   width: 80,
        // },
        {
          title: "姓名",
          key: "name",
        },
        {
          title: "手机号",
          key: "phone",
        },
        {
          title: "所属企业",
          key: "corpName",
        },
        {
          title: "授权角色",
          key: "roleName",
        },
        {
          title: "操作",
          slot: "action",
          width: 220,
          align: "center",
        },
      ],
      recordColumnsList:[

        {
          title: "用户名",
          key: "username",
        },
        {
          title: "ip",
          key: "terminalIp",
        },
        {
          title: "模块",
          key: "operationType",
        },
        {
          title: "操作",
          key: "operationDesc",
        },
         {
          title: "创建时间",
          key: "createTime",
        },
        
      ],
      userColumnsList:[
        {
          title: "id",
          key: "id",
        },
        {
          title: "手机号码",
          key: "phone",
        },
        {
          title: "姓名",
          key: "realName",
        },
        {
          title: "身份证",
          key: "idCard",
        },
        {
          title: "性别",
          key: "sex",
          slot: "sex",
        },
         {
          title: "是否实名",
          key: "isRealname",
          slot: "isRealname",

        },
         {
          title: "是否缺任务",
          key: "publishTaskStatus",
          slot: "publishTaskStatus",

        },
         {
          title: "班组名称",
          key: "teamName",
        },
        {
          title: "项目名称",
          key: "projectName",
        },
        {
          title: "企业名称",
          key: "corpName",
        },
        
        
      ],

      authDataList: [],
      authDataList1: [],

      recordDataList: [
        // {
        //   id:1,
        //   record1:"内容1",
        //   record2:"内容1",
        //   record3:"内容1",
        //   record4:"内容1",

        // }, {
        //   id:2,
        //  record1:"内容2",
        //   record2:"内容2",
        //   record3:"内容2",
        //   record4:"内容2",
        // }, {
        //   id:3,
        //  record1:"内容3",
        //   record2:"内容3",
        //   record3:"内容3",
        //   record4:"内容3",
        // }, {
        //   id:4,
        //  record1:"内容4",
        //   record2:"内容4",
        //   record3:"内容4",
        //   record4:"内容4",
        // },
      ],
      recordListLoading:false,
      recordListPage: 1,
      recordListTotal: 0,
      recordListSize: 10,



      userDataList: [],
      userListLoading:false,
      userListPage: 1,
      userListTotal: 0,
      userListSize: 10,

      areaAuthColumnsList: [
        {
          title: "姓名",
          key: "name",
        },
        {
          title: "手机号",
          key: "phone",
        },
        {
          title: "管辖片区",
          key: "zoneCodeStr",
        },
        {
          title: "操作",
          slot: "action",
          width: 200,
          align: "center",
        },
      ],
      areaAuthDataList: [],
      file: null,
      pdfFile: null,
      safeSelectValue: [],
      currentSafeContent: "",
      safeSelectData: [],
      versionList: [],
      pickSafeListId: [],
      editSafeFlag: false,
      getSafeContentLoading: false,
      currentVersion: "",
      copySafeDiscloseFlag: false,
      addNewBlackFlag: false,
      addNewBlackNext: true,
      newBlackData: {
        name: "", // name	是	string	姓名
        idcard: "", // idcard	是	string	身份证
        corpId: "", // corpId	是	int	企业id
        nativePlace: "", // nativePlace	否	string	籍贯
        eventTeamName: "", // eventTeamName	否	string	事件班组名称
        eventProjectName: "", // eventProjectName	否	string	事件项目名称
        event: "", // event	否	string	事件
        eventTime: "", // eventTime	否	string	事件时间
        remark: "", // remark	否	string	备注
        source: "", // source	否	int	来源，0-企业，1-政府
      },
      sourceList: [
        {
          value: 0,
          label: "企业",
        },
        {
          value: 1,
          label: "政府",
        },
      ],
      editBlackFlag: false,
      videoColumsList: [
        {
          title: "视频封面",
          slot: "icon",
          width: 120,
          align: "",
        },
        {
          title: "视频标题",
          key: "title",
        },
        {
          title: "视频分类",
          key: "cateStr",
        },
        {
          title: "视频时长（分钟）",
          key: "durationMin",
        },
        {
          title: "视频高度",
          key: "height",
        },
        {
          title: "视频宽度",
          key: "width",
        },
        {
          title: "添加时间",
          key: "createTime",
        },
        {
          title: "更新时间",
          key: "updateTime",
        },
        {
          title: "视频简介",
          key: "desp",
        },
        {
          title: "视频状态",
          key: "transcodeStr",
        },
        // {
        //   title: "备注",
        //   key: "remark",
        // },
        {
          title: "操作",
          slot: "action",
          width: 360,
          align: "center",
        },
      ],
      videoDataList: [],
      videoListLoading: false,
      videoListPage: 1,
      videoListSize: 15,
      videoListTotal: 0,
      searchVideoData: "",
      showQRFlag: false,
      showImgSrc: "",
      newVideoData: {
        title: "",
        type: "",
        desc: "",
      },
      videoTypeList: [],
      pdfId: "",
      uploader_key: new Date().getTime(),
      options: {
        target: "/sapi/chunk/upload",
        testChunks: false,
        query: {
          corpId: JSON.stringify(
            JSON.parse(localStorage.getItem("user_info")).icorpid
          ),
        },
      },
      attrs: {
        accept: [
          ".png",
          ".jpg",
          ".jpeg",
          ".gif",
          ".bmp",
          ".mp4",
          ".wmv",
          ".avi",
          ".rmvb",
        ],
      },
      uploadFile: null,
      videoFileId: "",
      videoLoading: false,
      editVideoId: "",
      areaAuthFlag: false,
      zoneList: [],
      workTypeList: [],
      corpWorkType: "",
      hasCorpWorkType: false,
      workwork:{
        corpWorkType: [],
      },
      userInfo: {},


      corpOptions:[],
      projectOptions: [],
      teamOptions:[],
    };
  },
  mounted() {
    let userInfo = this.common.getStorage(this.CONSTANTS.USER_INFO);
    this.userInfo = userInfo

      if(this.userInfo.maxRoleType=='ADMINISTRATOR'){
        this.activeName= "userlist";
        this.openName=["userlist"];
        this.currentBox= "userlist";

      }else{
        this.activeName= "auth";
        this.openName=["auth"];
        this.currentBox= "auth";
      }

    if (userInfo) {
      this.currentUserInfo = userInfo;
      this.getAuthList();
      // 判断有没有片区管理功能
      this.getAreaAuth();
      this.uploadData.corpId = userInfo.icorpid;


      // 用户列表
      this.getCorps();
      this.getUserList();
    } else {
      this.$router.push({ name: "login" });
    }
  },
  watch: {
    openName() {
      if(this.userInfo.maxRoleType!=='ADMINISTRATOR'){
        this.$nextTick(() => {
        this.$refs.side_menu.updateOpened();
        this.$refs.side_menu.updateActiveName();
      });
      }
     
    },
  },
  methods: {
    handleNavChange(e) {
      this.currentBox = e;
      if (e == "auth") {
        this.authListLoading = true;
        this.getAuthList();
      }
      if (e == "type") {
        this.getCorpWorkType();
        this.getWorkTypeList();
      }
      if (e == "blacklist") {
        this.blackListPage = 1;
        this.searchBlackListData = {
          name: "",
          phone: "",
          idcard: "",
        };
        this.getBlackList();
      }
      if (e == "safelist") {
        this.safeSelectValue = [];
        this.currentSafeContent = "";
        this.getSafeList();
        this.getSafeYearList();
      }
      if (e == "videolist") {
        this.searchVideoData = "";
        this.videoListPage = 1;
        this.getVideoList();
      }
      if (e == "videoedit") {
        this.pdfId = "";
        this.videoFileId = "";
        this.editVideoId = "";
        this.getVideoTypeList();
      }
      if (e == "recordlist") {
        this.searchRecordListData = {
          keyword: "",
        };
        this.recordListLoading = true;
        this.getRecordList();
      }
      // 用户列表
      if (e == "userlist") {
        this.searchUserListData = {
          pageNo: this.userListPage,
          pageSize:this.userListSize,
          desc: 'id',
          query: null,
          corpId:null,
          projectId:null,
          teamId:null,
          publishTaskStatus: null,
        };
        this.userListLoading = true;
        this.getCorps();
        this.getUserList();


      }
      if (e == "areaauth") {
        this.getZoneList();
        this.getAreaAuthList();
      }
      if (e == 'overView'){
        let routeUrl = this.$router.resolve({
                path: '/project/overView'
            });
        window.open(routeUrl.href, '_blank');
      }
    },
    // /sapi/mutliUser/getMutliUsers
    // /sapi/mutliUser/setMutliUser
    // /sapi/mutliUser/delMultiUsers
    getAuthList() {
      this.authListLoading = true;
      let data = {
        icorpid: this.currentUserInfo.icorpid,
        roleId: "1",
      };
      this.$fetch("/sapi/mutliUser/getMutliUsers", {
        method: "post",
        data,
      })
        .then((res) => {
          if (res.code == "200") {
            // this.authDataList = res.data;
            this.authDataList1 = res.data;

            this.authDataList= JSON.parse(JSON.stringify(this.authDataList1));//处理深拷贝  浅拷贝
            // 默认先脱敏
            this.authDataList.forEach((e) => {
              e.name = e.name?nameConceal(e.name.toString()):'';//姓名
              e.phone = e.phone?phoneConceal(e.phone.toString()):'';//手机号

              e.showOrHide = "hide";//显示/隐藏
            });

          } else {
            this.$Message.error("获取授权列表失败");
          }
          this.authListLoading = false;
        })
        .catch((err) => {
          // this.$Message.error("获取授权列表失败");
          this.authListLoading = false;
        });
    },
   // 展示
    showAuthConceal(data){
       this.authDataList1.forEach((item,index)=>{
        if(data.id==item.id){
         data.name = item.name;//姓名
         data.phone = item.phone;//手机号
         data.showOrHide = "show";//显示/隐藏
        }
      })
    },
    //隐藏
    hideAuthConceal(data){
      this.authDataList1.forEach((item,index)=>{
        if(data.id==item.id){
         data.name = item.name?nameConceal(item.name.toString()):'';//姓名
         data.phone= item.phone?phoneConceal(item.phone.toString()):'';//手机号
         data.showOrHide = "hide";//显示/隐藏
        }
      })
    },
     getRecordList() {
      this.recordListLoading = true;
      let data = {
        pageNo:this.recordListPage,
        pageSize: this.recordListSize,
        keyword:this.searchRecordListData.keyword,
        // startTime:this.searchRecordListData.startTime,
        // endTime:this.searchRecordListData.endTime,
      };
      console.log(data);
      // this.$fetch(`${this.apiDgtechUrlCros}/dgtech/api/mgmt/system/log`, {
      //   method: "GET",
      //   data,
      // })
      // this.$axios
      //   .get(`${this.apiDgtechUrlCros}/dgtech/api/mgmt/system/log`,data)
      const storage = new Storage()
      const CONSTANTS = constants
      let token = storage.get(CONSTANTS.EOVA_AUTH);
      this.$axios({url:`${this.apiDgtechUrlCros}/dgtech/api/mgmt/system/log`, method:"get",params:data,headers: {'accessToken': token.accessToken}})
        .then((res) => {
          console.log(res);
          if (res.status==200) {
            if(res.data.resultStatus){
              this.recordDataList = res.data.resultData.records;
              this.recordListTotal = res.data.resultData.total;
            }else if(res.data.errorCode=='001004'){
              this.$Message.error("登录失效");
              this.$router.push({ name: 'login' });
            }
            
          }
           else {
            this.$Message.error("获取操作日志列表失败");
          }
          this.recordListLoading = false;
        })
        .catch((err) => {
          // this.$Message.error("获取操作日志列表失败");
          this.recordListLoading = false;
        });
    },
    // 用户列表
     getUserList() {
      this.userListLoading = true;
      const storage = new Storage()
      const CONSTANTS = constants
      let token = storage.get(CONSTANTS.EOVA_AUTH);
      this.$axios({url:`${this.apiDgtechUrlCros}/dgtech/api/mgmt/users`, method:"get",params:this.searchUserListData,headers: {'accessToken': token.accessToken}})
        .then((res) => {
            if(res.status==200){
              this.userDataList = res.data.resultData.dataList;
              this.userListTotal = res.data.resultData.totalNum;
            }else if(res.data.errorCode=='001004'){
              this.$Message.error("登录失效");
              this.$router.push({ name: 'login' });
            }else {
            this.$Message.error("获取用户列表失败");
          }
          this.userListLoading = false;
        })
        .catch((err) => {
          // this.$Message.error("获取用户列表失败");
          this.userListLoading = false;
        });
    },

    onOptionCorpChange(){
      this.getProjects()
      this.getUserList()
    },
    onOptionProjectChange(){
      this.getTeams()
      this.getUserList()
    },
    onOptionTeamChange(){
      this.getUserList()
    },
     getCorps(){
      const storage = new Storage()
      const CONSTANTS = constants
      let token = storage.get(CONSTANTS.EOVA_AUTH);
      let param = {size: -1}
      this.$axios({url:`${this.apiDgtechUrlCros}/dgtech/api/mgmt/corp`, method:'get', params: param,headers: {'accessToken': token.accessToken}}).then(res =>{
       if (res.status==200) {
          this.corpOptions = res.data.resultData
        }else{
          this.$Message.error("获取企业列表失败");
        }
      })
    },
     getProjects(){
       const storage = new Storage()
      const CONSTANTS = constants
      let token = storage.get(CONSTANTS.EOVA_AUTH);
      let param = {size: -1, icorpid: this.searchUserListData.corpId}
      this.$axios({url:`${this.apiDgtechUrlCros}/dgtech/api/mgmt/hr/projects`, method:'get', params: param,headers: {'accessToken': token.accessToken}}).then(res =>{
         if (res.status==200) {
          this.projectOptions = res.data.resultData
         }else{
          this.$Message.error("获取项目列表失败");
         }
      })
    },

     getTeams(){
       const storage = new Storage()
      const CONSTANTS = constants
      let token = storage.get(CONSTANTS.EOVA_AUTH);
      let param = {size: -1, iprojectid: this.searchUserListData.projectId}
      this.$axios( {url:`${this.apiDgtechUrlCros}/dgtech/api/mgmt/hr/teams`,method:'get', params: param,headers: {'accessToken': token.accessToken}}).then(res =>{
        if (res.status==200) {
           this.teamOptions = res.data.resultData
         }else{
          this.$Message.error("获取班组列表失败");
         }
      })
    },

    toAddCorpWorkType() {
      if (this.workwork.corpWorkType.length === 0) {
        this.$Message.info("请先选择工种");
        return;
      }
      // this.$Modal.confirm({
      //   title: "提示",
      //   content: "<p>添加后不能修改企业工种</p>",
      //   onOk: () => {
          
      //   let data = {
      //     id: this.currentUserInfo.icorpid,
      //     workTypeValue: this.corpWorkType,
      //   };
      //   this.$fetch(`/sapi/workType/relate/addCorpRelate`, {
      //     method: "post",
      //     data,
      //   })
      //     .then((res) => {
      //       if (res.code == "200") {
      //         console.log(res);
      //         this.$Message.success("操作成功");
      //         this.getCorpWorkType();
      //         // this.workTypeList = res.data;
      //       } else {
      //         this.$Message.warning("操作失败");
      //       }
      //     })
      //     .catch((err) => {
      //       this.$Message.warning("操作失败");
      //     });
      //   },
      //   onCancel: () => {},
      // });
      let data = {
          id: this.currentUserInfo.icorpid,
          workTypeValues: this.workwork.corpWorkType,
        };
        this.$fetch(`/sapi/workType/relate/addCorpRelate`, {
          method: "post",
          data,
        })
          .then((res) => {
            if (res.code == "200") {
              this.$Message.success("操作成功");
              this.getCorpWorkType();
              // this.workTypeList = res.data;
            } else {
              this.$Message.warning("操作失败");
            }
          })
          .catch((err) => {
            // this.$Message.warning("操作失败");
          });
    },
    // 获取企业当前工种
    getCorpWorkType() {
      let data = {};
      this.$fetch(
        `/sapi/workType/relate/getCorpRelate?corpId=${this.currentUserInfo.icorpid}`,
        {
          method: "get",
          data,
        }
      )
        .then((res) => {
          if (res.code == "200") {
            // this.hasCorpWorkType = res.data.length > 0 ? true : false;
            // this.corpWorkType = res.data.length > 0 ? res.data[0].value : "";
            if(res.data.length > 0){
              let subTxt = ''
              let arr = []
              res.data.forEach(val=>{
                arr.push(val.value)
                val.childs.forEach(item => {
                  subTxt = subTxt + '、' + item.name
                })
              })
                this.subWorkType = subTxt
                this.workwork.corpWorkType = arr
            }else{
              this.workwork.corpWorkType = ""
            }
          } else {
            this.$Message.error("获取工种失败");
          }
        })
        .catch((err) => {
          // this.$Message.error("获取工种失败");
        });
    },
    // 获取工种
    getWorkTypeList() {
      let data = {};
      this.$fetch("/sapi/workType/tree/list", {
        method: "get",
        data,
      })
        .then((res) => {
          if (res.code == "200") {
            this.workTypeList = res.data;
          } else {
            this.$Message.error("获取工种失败");
          }
        })
        .catch((err) => {
          // this.$Message.error("获取工种失败");
        });
    },
    editZoneAuth(e) {
      // console.log(e)
      this.newAuthData = {
        name: e.name,
        phone: e.phone,
        authArea: e.zoneCode.split(","),
      };
      this.addNewAuthFlag = true;
    },
    toAddAuth() {
      this.newAuthData = {
        name: "",
        phone: "",
        authArea: [],
      };
      this.addNewAuthFlag = true;
    },
    addAuth(type) {
      if (type == "close") {
        this.addNewAuthFlag = false;
        return false;
      }
      if (this.newAuthData.name == "" || this.newAuthData.phone == "") {
        this.$Message.warning("授权人员信息不能为空");
        this.addAuthNext = false;
        setTimeout(() => {
          this.addAuthNext = true;
        }, 100);
        return false;
      }
      let areaAuthStr = "";
      if (this.areaAuthFlag && this.currentBox == "areaauth") {
        if (this.newAuthData.authArea.length < 1) {
          this.$Message.warning("管理片区不能为空");
          this.addAuthNext = false;
          setTimeout(() => {
            this.addAuthNext = true;
          }, 100);
          return false;
        } else {
          areaAuthStr = this.newAuthData.authArea.join(",");
          // console.log(areaAuthStr);
          let data = {
            phone: this.newAuthData.phone,
            name: this.newAuthData.name,
            icorpid: this.currentUserInfo.icorpid,
            zoneCode: areaAuthStr,
          };
          this.$fetch("/sapi/zoneUser/setZoneAdmin", {
            method: "post",
            data,
          })
            .then((res) => {
              if (res.code == "200") {
                this.$Message.success("新增授权成功");
                this.addNewAuthFlag = false;
                this.getAreaAuthList();
              } else {
                this.$Message.error("新增失败，稍后再试");
                this.addNewAuthFlag = false;
              }
            })
            .catch((err) => {
              //this.$Message.error("新增失败，稍后再试");
              this.addNewAuthFlag = false;
            });
        }
      } else {
        const storage = new Storage()
        const CONSTANTS = constants
        let token = storage.get(CONSTANTS.EOVA_AUTH);
        // let data = {
        //   multiUser: [
        //     {
        //       phone: this.newAuthData.phone,
        //       name: this.newAuthData.name,
        //       icorpid: this.currentUserInfo.icorpid,
        //       roleId: "1",
        //     },
        //   ],
        // };
        // this.$fetch("/sapi/mutliUser/setMutliUser", {
        //   method: "post",
        //   data,
        // })
        let data = {
              phone: this.newAuthData.phone,
              name: this.newAuthData.name,
              corpId: this.currentUserInfo.icorpid,
              roleId: "1",
            }
        this.$axios({url:`${this.apiDgtechUrlCros}/dgtech/api/mgmt/mutli`, method:"post",data:{multiUserList:[data]},
        // this.$axios({url:`/dgtech/api/mgmt/mutli`, method:"post",data:{multiUserList:[data]},
            headers: {'accessToken': token.accessToken}})
          .then((res) => {
            if (res.data.resultStatus) {
              this.$Message.success("新增授权成功");
              this.addNewAuthFlag = false;
              this.getAuthList();
            } else {
              this.$Message.error("新增失败，稍后再试");
              this.addNewAuthFlag = false;
            }
          })
          .catch((err) => {
            //this.$Message.error("新增失败，稍后再试");
            this.addNewAuthFlag = false;
          });
      }
    },
    addBlackMan() {
      this.addNewBlackFlag = true;
      this.newBlackData = {
        name: "",
        idcard: "",
        corpId: "",
        nativePlace: "",
        eventTeamName: "",
        eventProjectName: "",
        event: "",
        eventTime: "",
        remark: "",
        source: "",
      };
    },
    addBlack(type) {
      if (type == "close") {
        this.addNewBlackFlag = false;
        return false;
      }
      if (this.newBlackData.name == "" || this.newBlackData.idcard == "") {
        this.$Message.warning("请完整填写所需信息");
        this.addNewBlackNext = false;
        setTimeout(() => {
          this.addNewBlackNext = true;
        }, 100);
        return false;
      }
      let data = {};
      if (this.editBlackFlag) {
        data = this.newBlackData;
      } else {
        data = {
          name: this.newBlackData.name,
          idcard: this.newBlackData.idcard,
          teamId: "",
          projectId: "",
          nativePlace: this.newBlackData.nativePlace,
          eventTeamName: this.newBlackData.eventTeamName,
          eventProjectName: this.newBlackData.eventProjectName,
          event: this.newBlackData.event,
          eventTime: this.newBlackData.eventTime,
          remark: this.newBlackData.remark,
          source: this.newBlackData.source,
          corpId: this.currentUserInfo.icorpid,
        };
      }
      this.$fetch("/sapi/blackList/save", {
        method: "post",
        data,
      })
        .then((res) => {
          if (res.code == "200") {
            if (this.editBlackFlag) {
              this.$Message.success("修改失信人员成功");
            } else {
              this.$Message.success("新增失信人员成功");
            }
            this.editBlackFlag = false;
            this.addNewBlackFlag = false;
            this.blackListPage = 1;
            this.searchBlackListData = {
              name: "",
              phone: "",
              idcard: "",
            };
            this.getBlackList();
          } else {
            this.$Message.error("新增失败，稍后再试");
            this.addNewBlackFlag = false;
            this.editBlackFlag = false;
          }
        })
        .catch((err) => {
         // this.$Message.error("新增失败，稍后再试");
          this.addNewBlackFlag = false;
          this.editBlackFlag = false;
        });
    },
    editBlack(val) {
      this.newBlackData = val;
      this.editBlackFlag = true;
      this.addNewBlackFlag = true;
    },
    delAuth(e) {
      this.$Modal.confirm({
        title: "提示",
        content: "<p>是否确认移除该成员权限</p>",
        onOk: () => {
          let data = {
            id: e.id,
          };
          this.$fetch("/sapi/mutliUser/delMultiUsers", {
            method: "post",
            data,
          })
            .then((res) => {
              if (res.code == "200") {
                this.$Message.success("移除成功");
                this.getAuthList();
              } else {
                this.$Message.error("移除失败，稍后再试");
              }
            })
            .catch((err) => {
              // this.$Message.error("移除失败，稍后再试");
            });
        },
        onCancel: () => {
          // this.$Message.info('Clicked cancel');
        },
      });
    },
    delZoneAuth(e) {
      this.$Modal.confirm({
        title: "提示",
        content: "<p>是否确认移除该成员权限</p>",
        onOk: () => {
          let data = {
            phone: e.phone,
            name: e.name,
            icorpid: this.currentUserInfo.icorpid,
            zoneCode: "",
          };
          this.$fetch("/sapi/zoneUser/setZoneAdmin", {
            method: "post",
            data,
          })
            .then((res) => {
              if (res.code == "200") {
                this.$Message.success("移除成功");
                this.getAreaAuthList();
              } else {
                this.$Message.error("操作失败，稍后再试");
              }
            })
            .catch((err) => {
              // this.$Message.error("操作失败，稍后再试");
            });
        },
        onCancel: () => {
          // this.$Message.info('Clicked cancel');
        },
      });
    },
    getBlackList() {
      this.blackListLoading = true;
      let data = {
        corpId: this.currentUserInfo.icorpid,
        pageNumber: this.blackListPage,
        pageSize: this.blackListSize,
        phone: this.searchBlackListData.phone || "",
        name: this.searchBlackListData.name || "",
        idcard: this.searchBlackListData.idcard || "",
      };
      this.$fetch("/sapi/blackList/getBlackList", {
        method: "post",
        data,
      })
        .then((res) => {
          if (res.code == "200") {
            this.blackListTotal = res.total;
            // this.blackDataList = res.resList;
            this.blackDataList1 = res.resList;

            this.blackDataList= JSON.parse(JSON.stringify(this.blackDataList1));//处理深拷贝  浅拷贝
              // 默认先脱敏
            this.blackDataList.forEach((e) => {
              e.name = e.name?nameConceal(e.name.toString()):'';//姓名
              e.idcard = e.idcard?IdcardConceal(e.idcard.toString()):'';//身份证
              e.nativePlace = e.nativePlace?addressConceal(e.nativePlace.toString()):'';//籍贯


              e.showOrHide = "hide";//显示/隐藏
            });


          } else {
            this.$Message.error("获取失信名单列表失败");
          }
          this.blackListLoading = false;
        })
        .catch((err) => {
          // this.$Message.error("获取失信名单列表失败");
          this.blackListLoading = false;
        });
    },
    // 展示
    showBlackConceal(data){
       this.blackDataList1.forEach((item,index)=>{
        if(data.id==item.id){
         data.name = item.name;//姓名
         data.idcard = item.idcard;//身份证
         data.nativePlace = item.nativePlace;//籍贯

         data.showOrHide = "show";//显示/隐藏
        }
      })
    },
    //隐藏
    hideBlackConceal(data){
      this.blackDataList1.forEach((item,index)=>{
        if(data.id==item.id){
         data.name = item.name?nameConceal(item.name.toString()):'';//姓名
         data.idcard= item.idcard?IdcardConceal(item.idcard.toString()):'';//身份证
         data.nativePlace= item.nativePlace?phoneConceal(item.nativePlace.toString()):'';//籍贯
         data.showOrHide = "hide";//显示/隐藏
        }
      })
    },
    handleBlacklistPageChange(e) {
      this.blackListPage = e;
      this.getBlackList();
    },
    // 操作日志列表的翻页
    handleRecordlistPageChange(e) {
      this.recordListPage = e;
      this.getRecordList();
    },
    // 用户列表列表的翻页
    handleUserlistPageChange(e) {
      this.searchUserListData.pageNo = e;
      this.searchUserListData.pageSize = 10;

      this.getUserList();
    },
    handleBlacklistSearch(type) {
      if (type == "reset") {
        this.searchBlackListData = {
          name: "",
          phone: "",
          idcard: "",
        };
      }
      this.blackListPage = 1;
      this.getBlackList();
    },
    handleRecordlistSearch(type) {
      if (type == "reset") {
        this.searchRecordListData = {
          keyword: "",
          // startTime:undefined,
          // endTime:undefined,
        };
      }
      this.recordListPage = 1;
      this.getRecordList();
    },

    // 用户管理
    handleUserlistSearch(type) {
      if (type == "reset") {
        this.searchUserListData = {
          pageNo: this.userListPage,
          pageSize:this.userListSize,
          desc: 'id',
          query: null,
          corpId:null,
          projectId:null,
          teamId:null,
          publishTaskStatus: null,
        
        };
      }
      this.getUserList();
    },
    removeBlackList(e) {
      this.$Modal.confirm({
        title: "提示",
        content: "<p>是否确认移除该失信人员</p>",
        onOk: () => {
          let data = {
            corpId: this.currentUserInfo.icorpid,
            phone: this.currentUserInfo.login_id,
            id: e.id,
          };
          this.$fetch("/sapi/blackList/remove", {
            method: "post",
            data,
          })
            .then((res) => {
              if (res.code == "200") {
                this.$Message.success("移除成功");
                this.blackListPage = 1;
                this.getBlackList();
              } else {
                this.$Message.error("移除失败，稍后再试");
              }
            })
            .catch((err) => {
              // this.$Message.error("移除失败，稍后再试");
            });
        },
        onCancel: () => {
          // this.$Message.info('Clicked cancel');
        },
      });
    },
    downLoadBalckListModel() {
      let downUrl = "";
      downUrl = this.common.openUrl(
        "9000",
        "rlapi",
        `/template/downloadBlackListTemplate`
      );
      window.open(downUrl, "_blank");
    },
    onBefore(file) {
      this.file = file;
      this.senBlackList();
      return false;
    },
    onBeforePdf(file) {
      this.pdfFile = file;
      this.senVideopdf();
      return false;
    },
    senBlackList() {
      let formData = new FormData();
      formData.append("file", this.file);
      formData.append("corpId", this.currentUserInfo.icorpid);
      formData.append("phone", this.currentUserInfo.login_id);
      this.$fetch("/sapi/blackList/upload", {
        method: "post",
        data: formData,
        headers: {
          //添加请求头
          "Content-Type":
            "multipart/form-data;boundary = " + new Date().getTime(),
        },
      })
        .then((res) => {
          if (res.code == "200") {
            this.file = null;
            this.$Message.success("导入成功");
            this.handleBlacklistSearch("reset");
          } else {
            this.file = null;
            this.$Message.error("导入失败，稍后再试");
          }
        })
        .catch((err) => {
          this.file = null;
          // this.$Message.error(err);
        });
    },
    senVideopdf() {
      this.pdfId = "";
      let formData = new FormData();
      formData.append("file", this.pdfFile);
      formData.append("corpId", this.currentUserInfo.icorpid);
      this.$fetch("/sapi/corpVideo/uploadPdf", {
        method: "post",
        data: formData,
        headers: {
          //添加请求头
          "Content-Type":
            "multipart/form-data;boundary = " + new Date().getTime(),
        },
      })
        .then((res) => {
          if (res.code == "200") {
            this.pdfFile = null;
            this.$Message.success("上传成功");
            this.pdfId = res.data.id;
            // console.log(this.pdfId);
            // 处理上传pdf
            // this.handleBlacklistSearch("reset");
          } else {
            this.pdfFile = null;
            this.$Message.error("上传失败，稍后再试");
          }
        })
        .catch((err) => {
          this.pdfFile = null;
          // this.$Message.error(err);
        });
    },
    getSafeList() {
      let data = {};
      this.$fetch(
        `/sapi/safeDisclose/getCategory?corpId=${this.currentUserInfo.icorpid}`,
        {
          method: "post",
          data,
        }
      ).then((res) => {
        if (res && res.code === "200") {
          let datalist = res.data;
          let list = [];
          datalist.forEach((val) => {
            let arr = [];
            let clist = val.childs;
            for (let i = 0; i < clist.length; i++) {
              arr.push({
                value: clist[i].id + "",
                label: clist[i].name,
              });
            }
            list.push({
              value: val.id + "",
              label: val.name,
              children: arr,
            });
          });
          this.safeSelectData = list;
        } else {
          this.$Message.error("获取安全交底表格失败，请稍后再试");
        }
        // console.log(res)
      });
    },
    getSafeYearList() {
      let data = {
        type: "platfrom",
      };
      this.$fetch(`/sapi/safeDisclose/getYearList`, {
        method: "post",
        data,
      }).then((res) => {
        if (res && res.code === "200") {
          this.handleYearList(res.data);
        } else {
          this.$Message.error("获取安全交底表格失败，请稍后再试");
        }
        // console.log(res)
      });
    },
    handleYearList(list) {
      let ylist = [];
      if (list.length > 0) {
        list.forEach((val, index) => {
          ylist.push({
            value: val,
            label: val + "版",
          });
        });
        this.versionList = ylist;
      } else {
        this.versionList = [];
      }
    },
    handleSafeSelectChange(value, selectedData) {
      // console.log(value, selectedData);
      // this.safeSelectData
      this.safeSelectValue = selectedData;
      this.pickSafeListId = value;
      this.editSafeFlag = false;
      // 获取当前表内容
      if (value[1]) {
        this.getSafeContentLoading = true;
        this.getSafeContentProject(value[1]);
      } else {
        this.currentSafeContent = "";
      }
      // 空表时清除内容
    },
    // 获取安全表内容
    getSafeContentProject(tid) {
      let data = {
        type: "corp",
        id: tid,
      };
      this.$fetch(`/sapi/safeDisclose/getSafeDisclose`, {
        method: "post",
        data,
      }).then((res) => {
        if (res && res.code === "200") {
          // this.$Message.success("操作成功")
          // this.rosterListPage = 1
          // this.rosterListLoading = true
          // this.getRosterList()
          this.currentSafeContent = res.data.content;
          this.getSafeContentLoading = false;
        } else {
          this.$Message.error("获取安全交底表内容失败，请稍后再试");
        }
        // console.log(res)
      });
    },
    updateSafeDisclose() {
      this.currentVersion = "";
      this.copySafeDiscloseFlag = true;
    },
    // 根据版本号获取模板
    toPickVersion() {
      if (!this.currentVersion) {
        this.$Message.warning("请先选择版本");
        return false;
      }
      let data = {
        type: "corp",
        from: "platfrom",
        createBy: this.currentUserInfo.id,
        corpId: this.currentUserInfo.icorpid,
        year: this.currentVersion,
      };
      this.$fetch(`/sapi/safeDisclose/copy`, {
        method: "post",
        data,
      }).then((res) => {
        if (res && res.code === "200") {
          this.$Message.success("获取成功");
          this.editSafeFlag = false;
          this.pickSafeListId = [];
          this.safeSelectData = [];
          this.currentSafeContent = "";
          this.safeSelectValue = [];
          this.getSafeList();
        } else {
          this.$Message.error("操作失败，请稍后再试");
        }
      });
    },
    downSafeList() {
      let sid = this.pickSafeListId[1] || "";
      if (sid) {
        // var urlPath = window.document.location.href;
        // urlPath = urlPath.replace("https", "http");
        // var docPath = window.document.location.pathname;
        // var index = urlPath.indexOf(docPath);
        // var cip = urlPath
        //   .substring(0, index)
        //   .replace("8082", "8082")
        //   .replace("9988", "8082");
        // if (cip.indexOf("8082") === -1) {
        //   cip = cip + ":8082";
        // }
        // if (cip.indexOf("localhost") != -1) {
        //   cip = cip.replace("localhost", "134.175.132.191");
        // }
        // const fileUrl = `${cip}/api/safeDisclose/download?corpId=${this.currentUserInfo.icorpid}&cateId=${sid}`;
        let downUrl = "";
        downUrl = this.common.openUrl(
          "8082",
          "sapi",
          `/safeDisclose/download?corpId=${this.currentUserInfo.icorpid}&cateId=${sid}`
        );
        window.open(downUrl, "_blank");
      } else {
        this.$Message.warning("请先选择下载的安全交底表格");
      }
    },
    // 修改安全表内容
    toEditSafeContent() {
      if (this.pickSafeListId[1]) {
        this.editSafeFlag = true;
      } else {
        this.$Message.info("请先选择需要编辑的安全技术交底表");
      }
    },
    toChangeSafeContent(type) {
      if (type == "save") {
        this.$Modal.confirm({
          title: "提示",
          content: "<p>是否保存安全技术表格内容</p>",
          onOk: () => {
            let data = {
              id: this.pickSafeListId[1],
              type: "corp",
              corpId: this.currentUserInfo.icorpid,
              content: this.currentSafeContent,
            };
            this.$fetch(`/sapi/safeDisclose/editContent`, {
              method: "post",
              data,
            }).then((res) => {
              if (res && res.code === "200") {
                this.$Message.success("修改成功");
                this.editSafeFlag = false;
                this.currentSafeContent = "";
                this.getSafeContentLoading = true;
                this.getSafeContentProject(this.pickSafeListId[1]);
              } else {
                this.$Message.error("操作失败，请稍后再试");
              }
            });
          },
          onCancel: () => {},
        });
      } else if (type == "cancel") {
        this.editSafeFlag = false;
        this.currentSafeContent = "";
        this.getSafeContentLoading = true;
        this.getSafeContentProject(this.pickSafeListId[1]);
      }
    },
    getVideoList() {
      this.videoListLoading = true;
      let data = {
        corpId: this.currentUserInfo.icorpid,
        pageSize: this.videoListSize,
        pageNumber: this.videoListPage,
        title: this.searchVideoData,
      };
      this.$fetch("/sapi/corpVideo/getCorpVideoList", {
        method: "post",
        data,
      })
        .then((res) => {
          if (res.code == "200") {
            this.videoDataList = res.resList;
            this.videoListTotal = res.total;
          } else {
            this.$Message.error("获取视频列表失败");
          }
          this.videoListLoading = false;
        })
        .catch((err) => {
          // this.$Message.error("获取视频列表失败");
          this.videoListLoading = false;
        });
    },
    handleVideoListPageChange(e) {
      this.videoListPage = e;
      this.getVideoList();
    },
    handleVideoListSearch(type) {
      if (type == "reset") {
        this.searchVideoData = "";
      }
      this.getVideoList();
    },

    hiddenQR() {
      this.showQRFlag = !this.showQRFlag;
    },
    showQR(e) {
      if (!e) {
        return false;
      }
      this.showQRFlag = !this.showQRFlag;
      this.showImgSrc = e;
    },
    getVideoTypeList() {
      let data = {
        corpId: this.currentUserInfo.icorpid,
      };
      this.$fetch("/sapi/corpVideo/getCorpVideoCate", {
        method: "post",
        data,
      })
        .then((res) => {
          if (res.code == "200") {
            this.videoTypeList = res.data;
          } else {
            this.$Message.error("获取视频分类列表失败");
          }
        })
        .catch((err) => {
          // this.$Message.error("获取视频分类列表失败");
        });
    },
    uploadSuccess(file, response) {
      if (response.code == "200") {
        this.uploadFile.name = response.resData.fileName;
        this.uploadFile.size = response.resData.size;
        this.uploadFile.size_show = (response.resData.size / 10000000).toFixed(
          2
        );
        this.uploadFile.ext = response.resData.ext;
        this.uploadFile.frame_show =
          this.CONSTANTS.FRAME_URL + response.resData.frame;
        this.uploadFile.filePath_show =
          this.CONSTANTS.VIDEO_URL + response.resData.fileName;
        this.uploadFile.frame = response.resData.fileName;
        this.uploadFile.filePath = response.resData.file_path;
        this.uploadFile.preview_frame_path =
          response.resData.preview_frame_path;
        this.uploadFile.upload_time = response.resData.upload_time;
        this.uploadFile.id = response.resData.id;
        this.$Message.success("上传成功");
        this.$router.push({
          name: "releaseUpload",
          params: {
            uploadFile: this.uploadFile,
          },
        });
      } else {
        this.$Message.error(response.message);
        this.reload();
      }
    },
    uploadError(file) {
      this.$Message.error("上传失败");
      this.videoLoading = false;
      this.reload();
    },
    onFileSuccess: function (rootFile, file, response, chunk) {
      // console.log(rootFile, file, response, chunk)
      // console.log(JSON.parse(response).model);
      if (response) {
        console.log("上传完成", JSON.parse(response));
        let rdata = JSON.parse(response);
        this.videoFileId = rdata.data;
        this.videoLoading = false;
      }
    },
    computeMD5(file) {
      // console.log(file);
      // const loading = this.$loading({
      //   lock: true,
      //   text: '正在计算MD5',
      //   spinner: 'el-icon-loading',
      //   background: 'rgba(0, 0, 0, 0.7)'
      // });
      let fileReader = new FileReader();
      let time = new Date().getTime();
      let blobSlice =
        File.prototype.slice ||
        File.prototype.mozSlice ||
        File.prototype.webkitSlice;
      let currentChunk = 0;
      const chunkSize = 10 * 1024 * 1024;
      let chunks = Math.ceil(file.size / chunkSize);
      let spark = new SparkMD5.ArrayBuffer();
      file.pause();

      loadNext();

      fileReader.onload = (e) => {
        spark.append(e.target.result);
        if (currentChunk < chunks) {
          currentChunk++;
          loadNext();
          this.$nextTick(() => {
            // console.log(
            //   "校验MD5 " + ((currentChunk / chunks) * 100).toFixed(0) + "%"
            // );
          });
        } else {
          let md5 = spark.end();
          // loading.close();
          this.computeMD5Success(md5, file);
          // console.log(
          //   `MD5计算完毕：${file.name} \nMD5：${md5} \n分片：${chunks} 大小:${
          //     file.size
          //   } 用时：${new Date().getTime() - time} ms`
          // );
        }
      };
      fileReader.onerror = function () {
        this.error(`文件${file.name}读取出错，请检查该文件`);
        // loading.close();
        file.cancel();
      };

      function loadNext() {
        let start = currentChunk * chunkSize;
        let end =
          start + chunkSize >= file.size ? file.size : start + chunkSize;
        fileReader.readAsArrayBuffer(blobSlice.call(file.file, start, end));
      }
    },
    computeMD5Success(md5, file) {
      file.uniqueIdentifier = md5; //把md5值作为文件的识别码
      file.resume(); //开始上传
    },
    filesAdded(file, event) {
      //大小判断
      this.videoLoading = true;
      const isLt100M = file.size / 1024 / 1024 < 100;
      if (!isLt100M) {
        this.$Message.error(this.$t("error.error_upload_file_max"));
      } else {
        this.computeMD5(file);
      }
    },
    toSaveVideo(type) {
      if (type == "cancle") {
        this.newVideoData = {
          title: "",
          type: "",
          desc: "",
        };
        this.pdfId = "";
        this.videoFileId = "";
        this.searchVideoData = "";
        this.videoListPage = 1;
        this.getVideoList();
        this.currentBox = "videolist";
        return false;
      }
      if (this.videoLoading) {
        this.$Message.error("视频正在上传，请稍后再试。");
        return false;
      }
      if (
        !this.newVideoData.title ||
        !this.newVideoData.type ||
        !this.newVideoData.desc ||
        !this.videoFileId ||
        !this.pdfId
      ) {
        this.$Message.error("请完整填写内容");
        return false;
      }
      // console.log(
      //   this.newVideoData.title,
      //   this.newVideoData.type,
      //   this.newVideoData.desc,
      //   this.videoFileId,
      //   this.pdfId
      // );
      let data = {};
      let url = "";
      if (this.editVideoId) {
        data = {
          id: this.videoFileId,
          title: this.newVideoData.title,
          desp: this.newVideoData.desc,
          cateId: this.newVideoData.type,
          pdfAttachmentId: this.pdfId,
          id: this.editVideoId,
          corpId: this.currentUserInfo.icorpid,
        };
        url = "/sapi/corpVideo/updateCorpVideo";
      } else {
        data = {
          id: this.videoFileId,
          title: this.newVideoData.title,
          desp: this.newVideoData.desc,
          cateId: this.newVideoData.type,
          pdfAttachmentId: this.pdfId,
          corpId: this.currentUserInfo.icorpid,
        };
        url = "/sapi/corpVideo/updateCorpVideo";
      }

      this.$fetch(url, {
        method: "post",
        data,
      })
        .then((res) => {
          if (res.code == "200") {
            this.$Message.success("保存成功");
            this.newVideoData = {
              title: "",
              type: "",
              desc: "",
            };
            this.pdfId = "";
            this.videoFileId = "";
            this.searchVideoData = "";
            this.videoListPage = 1;
            this.getVideoList();
            this.currentBox = "videolist";
          } else {
            this.$Message.error("保存失败");
          }
        })
        .catch((err) => {
          // this.$Message.error("保存失败");
        });
    },
    removeVideo(val) {
      // console.log(val.id);
      this.$Modal.confirm({
        title: "提示",
        content: `<p>是否移除该视频数据</p>`,
        onOk: () => {
          let data = {
            id: val.id,
          };
          this.$fetch("/sapi/corpVideo/remove", {
            method: "post",
            data,
          }).then((res) => {
            if (res.code == "200") {
              this.$Message.success("移除成功");
              this.handleVideoListSearch("reset");
            } else {
              this.$Message.info(res.msg || "操作失败");
            }
            // console.log(res)
          });
        },
        onCancel: () => {},
      });
    },
    editVideo(val) {
      // console.log(val);
      this.getVideoTypeList();
      this.currentBox = "videoedit";
      this.editVideoId = val.id;
      this.newVideoData = {
        title: val.title,
        type: val.cateId,
        desc: val.desp,
      };
      this.videoFileId = val.fileId;
      this.pdfId = val.pdfAttachmentId;
    },
    handleAuthTypeChange(e) {
      // console.log(e);
      if (e == "1") {
        this.newAuthData.authArea = [];
      }
    },
    getAreaAuthList() {
      // teamItem/getTeamItems
      this.areaAuthListLoading = true;
      let data = {
        icorpid: this.currentUserInfo.icorpid,
        roleId: "6",
      };
      this.$fetch(`/sapi/zoneUser/zoneAdminList`, {
        method: "post",
        data,
      }).then((res) => {
        if (res && res.code === "200") {
          this.areaAuthDataList = res.resList;
          this.areaAuthListLoading = false;
        } else {
          this.areaAuthListLoading = false;
          this.$Message.error("获取片区管辖人员列表失败");
        }
      });
    },
    // 判断有没有片区管理功能
    getAreaAuth() {
      this.$axios
        .get(`${this.apiUrlCros}/sapi/corp/getZoneAuth?corpId=${this.currentUserInfo.icorpid}`)
        .then((res) => {
          if (res.data.code == "200") {
            this.areaAuthFlag = res.data.data;
          } else {
            this.areaAuthFlag = false;
            this.$Message.error("获取‘片区授权管理功能’权限失败");
          }
        })
        .catch((err) => {
          // this.$Message.error(err.message);
        });
    },
    getZoneList() {
      let data = {
        corpId: this.currentUserInfo.icorpid,
      };
      this.$fetch(`/sapi/zone/getZoneList`, {
        method: "post",
        data,
      }).then((res) => {
        if (res && res.code === "200") {
          let list = res.data;
          let zlist = [];
          list.forEach((val) => {
            zlist.push({
              value: val.code,
              label: val.name,
            });
          });
          this.zoneList = zlist;
        } else {
          this.$Message.error("获取片区列表失败");
        }
      });
    },
    toViewFile(src) {
      window.open(src, "_blank");
    },
  },
};
</script>
<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.uploader-example {
  width: 300px;
  font-size: 12px;
  position: relative;
  .uploader-drop {
    background: #fff !important;
  }
}
.console {
  display: flex;
  .left-nav {
    width: 260px;
    min-width: 260px;
    overflow-y: auto;
    background: #fff;
    // background: #515a6e;
    border-right: 1px solid #dcdee2;
    height: 100vh;
  }
  .right-main {
    padding: 10px;
    // width: 100%;
    flex: 1;
    .bread-box {
      margin-bottom: 10px;
    }
    .content-box {
      display: flex;
      // transition: all 1s;
      min-height: 600px;
      .new-btn-box {
        display: flex;
        flex-direction: column;
        padding: 12px 14px;
        .nbb-item {
          display: flex;
          // align-items: center;
          margin-bottom: 14px;
          .label {
            margin-right: 8px;
            padding-top: 4px;
          }
        }
      }
      .cb-btn-box {
        display: flex;
      }
      .page-box {
        margin-top: 20px;
        display: flex;
        justify-content: flex-start;
      }
      .edit-btn-box {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 10px;
        .ebb-style {
          margin-right: 10px;
        }
      }
      .second-title {
        margin-bottom: 6px;
        display: flex;
        align-items: center;
        .shu {
          width: 5px;
          height: 14px;
          background: #409eff;
          margin-right: 6px;
        }
      }
      .c-btnbox {
        display: flex;
        flex-direction: column;
        margin-bottom: 16px;
        padding-left: 14px;
        .c-btnbox-item {
          display: flex;
          .mb-item {
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
}
.tab-btnbox {
  display: flex;
  margin-bottom: 14px;
  margin-left: 14px;
}
.tab-textarea {
  margin-left: 14px;
}
.bgimg {
  position: absolute;
  z-index: 1;
  opacity: 0.03;
  width: 450px;
  right: -100px;
  bottom: -100px;
  img {
    width: 100%;
    height: auto;
  }
}
.aa-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  .label {
    width: 120px;
  }
  .bitian {
    position: relative;
  }
  .bitian::before {
    content: "*";
    position: absolute;
    left: -7px;
    top: 0px;
    color: red;
  }
}
.show-qrcode {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  .qrcode-img {
    width: 300px;
    img {
      width: 100%;
      height: auto;
    }
  }
}
.head-img {
  width: 80px;
  img {
    width: 100%;
    height: auto;
  }
}
.demo-spin-icon-load {
  animation: ani-demo-spin 1s linear infinite;
}
@keyframes ani-demo-spin {
  from {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.demo-spin-col {
  height: 100px;
  position: relative;
  border: 1px solid #eee;
}
// 鼠标移入效果
@keyframes myCardHover {
  0% {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.09);
  }
  100% {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15),
      0 6px 20px 0 rgba(0, 0, 0, 0.14);
  }
}
.count-part:hover {
  animation: myCardHover 0.0001s linear;
  animation-fill-mode: both;
}
</style>